<template>
  <v-dialog v-model="dialog" max-width="620px">
    <v-card>
      <v-card-title>
        <v-row align="center">
          <v-col cols="1">
            <v-icon size="20" @click="closeDialog">
              {{ icons.mdiClose }}
            </v-icon>
          </v-col>

          <v-col align="center">
            <h5>{{ formType === 'create' ? 'Add Employee' : 'Edit Employee' }}</h5>
          </v-col>

          <v-col cols="1"></v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>
      <v-form ref="form" @submit.prevent="submitForm">
        <v-card-text class="py-8 px-8">
          <v-text-field v-model="currentEmployee.name" label="Employee name" outlined :rules="nameRules">
          </v-text-field>
          <vue-phone-number-input
            id="AddEmployee"
            v-model="currentEmployee.phoneNumber"
            color="#F15858"
            valid-color="#F15858"
            error-color="#FF4C51"
            default-country-code="ID"
            clearable
            :rules="photoEmployeeUploaded !== 'No photo uploaded yet' ? '' : phoneNumberRules"
            size="lg"
            :dark="isDark"
            :error="
              phoneNumberInputData ? !phoneNumberInputData.isValid || Boolean(errors.length) : Boolean(errors.length)
            "
            @update="data => (phoneNumberInputData = data)"
          />
          <p :class="errors.length ? 'mb-5' : 'mb-7'" style="font-size: 0.8rem; color: red">
            {{ errors[0] }}
          </p>

          <v-text-field v-model="currentEmployee.email" label="Email" outlined> </v-text-field>

          <v-combobox
            v-model="selectedBank"
            label="Bank account (Optional)"
            :items="bankChannels"
            item-text="name"
            outlined
            return-object
            clearable
          >
          </v-combobox>

          <v-text-field v-model="currentEmployee.accountNumber" label="Account number (Optional)" outlined>
          </v-text-field>
          <uppy-uploader
            ref="uppyRef"
            :data-index="`${Math.round(Math.random() * 100000)}`"
            :data-uppy="uppyDataProps"
            :config="uppyConfig"
            :dashboard-config="uppyDashboardConfig"
            @uppy-file="uploadFileUppy"
          />
          <v-btn outlined class="show-uppy-modal"> Upload Photo Employee </v-btn>
          <span class="ml-3">{{ photoEmployeeUploaded }}</span>
          <!-- <v-row>
            <v-col v-for="(image,i) in imagesUrlForPreview" :key="i">
              <v-img :src="image" contain width="100" height="100"> </v-img>
            </v-col>
          </v-row> -->
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-end">
          <v-btn color="primary" type="submit" :loading="loading"> Save </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiClose } from '@mdi/js'
import UppyUploader from '@/components/UppyUploader.vue'
import bankChannels from './bank-channels.json'

export default {
  name: 'AddEmployee',
  components: { VuePhoneNumberInput, UppyUploader },
  props: {
    value: { type: Boolean, required: false },
    formType: { type: String, required: true },
    employeeGroupID: { type: String, required: true },
  },
  setup() {
    const { isDark } = useAppConfig()

    return {
      isDark,
      icons: { mdiClose },
    }
  },
  data() {
    return {
      selectedBank: null,
      bankChannels,
      bankAccountList: ['Bank Jago', 'BCA', 'Danamon', 'BNI'],
      phoneNumberInputData: null,
      loading: false,
      errors: [],

      // {R U L E S} //
      nameRules: [value => !!value || 'Name grup wajib diisi'],
      phoneNumberRules: [value => !!value || 'Phone Number wajib diisi'],

      /// /////////////
      imagesUrl: [],
      imagesUrlForPreview: [],
      uppyDataProps: null,
      uppyConfig: {
        autoProceed: true,
        allowMultipleUploads: true,
        restrictions: {
          maxFileSize: 100 * 1024 * 1024,
          maxNumberOfFiles: 5,
          minNumberOfFiles: 1,
          allowedFileTypes: ['.jpg', '.jpeg', '.png'],
        },
      },
      uppyDashboardConfig: {
        note: ' Image Only',
        theme: 'light',
        trigger: '.show-uppy-modal',
        inline: false,
        disabled: false,
        hideUploadButton: true,
      },
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    instance() {
      return this.$store.getters['auth/getListInstance']
    },
    currentEmployee() {
      return this.$store.getters['manageSalary/getCurrentEmployee']
    },
    photoEmployeeUploaded() {
      let photoEmployeeUploaded = []
      if (this.formType === 'create') {
        photoEmployeeUploaded = this.imagesUrlForPreview.length
      } else {
        //console.log(this.currentEmployee, 'currentEmployeee')
        photoEmployeeUploaded = this.currentEmployee?.employeePhotos.length + this.imagesUrlForPreview.length
      }

      if (!photoEmployeeUploaded) {
        return 'No photo uploaded yet'
      }
      if (photoEmployeeUploaded === 1) {
        return '1 photo has been uploaded'
      }

      return `${photoEmployeeUploaded} photos has been uploaded`
    },
  },

  watch: {
    formType(val) {
      if (val === 'create') {
        this.clearForm()
      } else if (val === 'edit') {
        this.selectedBank = this.currentEmployee.bankCode
          ? {
              code: this.currentEmployee.bankCode,
              name: this.currentEmployee.bankAccount,
            }
          : null
      }
    },
  },
  methods: {
    async submitForm() {
      this.$refs.form.validate()
      this.imagesUrl = this.uploadedImage().map(dataImage => {
        return dataImage.uploadURL
      })
      const employeeData = {
        name: this.currentEmployee.name,
        phoneNumber: this.currentEmployee.phoneNumber,
        email: this.currentEmployee.email,
        bankAccount: this.selectedBank?.name,
        bankCode: this.selectedBank?.code,
        accountNumber: this.currentEmployee.accountNumber,
        ID_employeeGroup: this.employeeGroupID,
        employeePhotos:
          this.formType === 'edit' ? this.imagesUrl.concat(this.currentEmployee.employeePhotos) : this.imagesUrl,
      }

      this.loading = true

      const validPhoneNumber = await this.validatePhoneNumber()
      if (!validPhoneNumber) {
        this.loading = false

        return
      }

      if (this.formType === 'create') {
        await this.$store.dispatch('manageSalary/createEmployee', {
          sub_id: this.user.sub_id,
          employee_data: employeeData,
        })

        await this.$store.dispatch('manageSalary/getEmployeesByEmployeeGroupID', {
          sub_id: this.user.sub_id,
          ID_employeeGroup: this.$route.params.id,
        })
      } else if (this.formType === 'edit') {
        await this.$store.dispatch('manageSalary/editEmployee', {
          sub_id: this.user.sub_id,
          employee_data: { ...this.currentEmployee, ...employeeData },
          employeeGroupId: this.$route.params.id,
        })

        await this.$store.dispatch('manageSalary/getEmployeesByEmployeeGroupID', {
          sub_id: this.user.sub_id,
          ID_employeeGroup: this.$route.params.id,
        })
      }

      this.loading = false
      this.dialog = false
      this.clearForm()
    },
    async validatePhoneNumber() {
      if (!this.imagesUrlForPreview.length && !this.currentEmployee.phoneNumber) {
        this.phoneNumberInputData = {
          isValid: false,
        }

        return false
      }
      this.phoneNumberInputData = {
        isValid: true,
      }

      return true
    },
    clearForm() {
      this.$store.commit('manageSalary/setCurrentEmployee', {
        name: '',
        phoneNumber: '',
        email: '',
        bankAccount: '',
        bankCode: '',
        accountNumber: '',
        employeePhotos: [],
      })
      this.selectedBank = null
      this.imagesUrlForPreview = []
      this.uppyDataProps = null
      this.imagesUrl = []
    },
    uploadedImage() {
      return this.$refs.uppyRef.uppy.getFiles()
    },
    uploadFileUppy(uploadUrl) {
      if (uploadUrl) {
        this.imagesUrlForPreview.push(uploadUrl)
      } else {
        this.imagesUrlForPreview.pop()
      }
    },
    closeDialog() {
      this.dialog = false
      this.imagesUrlForPreview = []
    },
  },
}
</script>

<style lang="scss" scoped></style>
